<template>
  <div v-for="item in tutorials_popup" v-bind:key="item.id"
       class="modal fade portfolio-modal text-center" role="dialog" tabindex="-1" :id="item.div_id">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="container">
            <div class="row">
              <div class="col-lg-8 mx-auto">
                <div class="modal-body">
                  <h2 class="text-uppercase">{{ item.title_1 }}</h2>
                  <p class="item-intro text-muted">{{ item.title_2 }}</p><img
                    class="img-fluid d-block mx-auto"
                    :src="item.image">
                  <p>{{ item.description }}</p>
                  <button class="btn btn-primary" data-dismiss="modal" type="button"><i
                      class="fa fa-times"></i><span> Κλείσιμο</span></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppTutorialsPopups",
  data() {
    return {
      tutorials_popup: [
        {
          div_id: 'guidemodal1',
          title_1: 'Οδηγιεσ',
          title_2: 'Οδηγίες γοα σπασμένο ή χαλαρό ελατήριο κλαρίνου',
          image: 'assets/img/guides/31_useclarinetregisterkey800x8001.jpg',
          description: 'Στην περίπτωση που κάποιο κλειδί του κλαρίνου δεν κλείνει, πιθανόν να έχει αδυνατήσει ή σπάσει το ελατήριο. Μπορείτε όμως να ελέγξετε αν η βίδα του κλειδιού είναι σφικτή ή σκουριασμένη και αν χρειάζεται να την λαδώσετε με ειδικό ψιλό λάδι. Αν δεν υπάρχει πρόβλημα με την βίδα τότε περάστε με τρόπο ένα λαστιχάκι πάνω από το κλειδί, για να συνεχίσετε την εξάσκηση ή την πρόβα.'
        },
        {
          div_id: 'guidemodal2',
          title_1: 'Οδηγιες',
          title_2: 'Αντικατάσταση χαλασμένης τάπας κλαρίνου',
          image: 'assets/img/guides/32_01.jpg',
          description: 'Στην περίπτωση που κάποιο κλειδί του κλαρίνου δεν κλείνει, πιθανόν να έχει αδυνατήσει ή σπάσει το ελατήριο. Μπορείτε όμως να ελέγξετε αν η βίδα του κλειδιού είναι σφικτή ή σκουριασμένη και αν χρειάζεται να την λαδώσετε με ειδικό ψιλό λάδι. Αν δεν υπάρχει πρόβλημα με την βίδα τότε περάστε με τρόπο ένα λαστιχάκι πάνω από το κλειδί, για να συνεχίσετε την εξάσκηση ή την πρόβα.'
        },
        {
          div_id: 'guidemodal3',
          title_1: 'Οδηγιες',
          title_2: 'Κολλημένο ή σφηνωμένο επιστόμιο τρομπέτας',
          image: 'assets/img/guides/33_fixtrumpetmouthpiece800x8001.jpg',
          description: 'Εάν το επιστόμιο της τρομπέτας κολλήσει ή σφηνώσει στον σωλήνα του επιστομίου, τότε μην προσπαθήσετε να το βγάλετε βίαια. Μην χρησιμοποιήσετε κάποια πένσα ή κάβουρα, διότι θα σημαδέψετε το επιστόμιο και μπορεί να σπάσουν οι κολώνες που στηρίζουν τον σωλήνα! Ωστόσο εάν θέλετε να δοκιμάσετε, πιάστε με το ένα χέρι με ένα πανί το επιστόμιο και σταθερά με το άλλο χέρι κι ένα πανί τον σωλήνα. Αν στρίβοντας το επιστόμιο δεν ξεκολλήσει με δυο - τρεις προσπάθειες, τότε πιθανόν να έχει χτυπηθεί. Αν το κόλλημα οφείλεται σε πέσιμο της τρομπέτας τότε το επιστόμιο είναι δύσκολο να αφαιρεθεί και χρειάζεται ειδικός εξολκέας..'
        },
        {
          div_id: 'guidemodal4',
          title_1: 'Οδηγιες',
          title_2: 'Κολλημένη βαλβίδα τρομπέτας',
          image: 'assets/img/guides/34_oiling_valve_500x3751.jpg',
          description: 'Κολλημένο, σφηνωμένο επιστόμιο τρομπέτας. Εάν το επιστόμιο της τρομπέτας κολλήσει ή σφηνώσει στον σωλήνα του επιστομίου, τότε μην προσπαθήσετε να το βγάλετε βίαια. Μην χρησιμοποιήσετε κάποια πένσα ή κάβουρα, διότι θα σημαδέψετε το επιστόμιο και μπορεί να σπάσουν οι κολώνες που στηρίζουν τον σωλήνα! Ωστόσο εάν θέλετε να δοκιμάσετε, πιάστε με το ένα χέρι με ένα πανί το επιστόμιο και σταθερά με το άλλο χέρι κι ένα πανί τον σωλήνα. Αν στρίβοντας το επιστόμιο δεν ξεκολλήσει με δυο - τρεις προσπάθειες, τότε πιθανόν να έχει χτυπηθεί. Αν το κόλλημα οφείλεται σε πέσιμο της τρομπέτας τότε το επιστόμιο είναι δύσκολο να αφαιρεθεί και χρειάζεται ειδικός εξολκέας..'
        }
      ]
    }
  },
}
</script>

<style scoped>

</style>
<template>
  <div v-for="item in technical_popups" v-bind:key="item.id"
       class="modal fade portfolio-modal text-center" role="dialog" tabindex="-1" :id="item.div_id">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="container">
            <div class="row">
              <div class="col-lg-8 mx-auto">
                <div class="modal-body">
                  <h2 class="text-uppercase">{{ item.title_1 }}</h2>
                  <p class="item-intro text-muted">{{ item.title_2 }}</p>
                  <img class="img-fluid d-block mx-auto" :src="item.image">
                  <p>{{ item.description }}</p>
                  <button class="btn btn-primary" data-dismiss="modal" type="button"><i
                      class="fa fa-times"></i><span> Κλείσιμο</span></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppTechnicalPopups",
  data() {
    return {
      technical_popups: [
        {
          div_id: 'guidemodal1',
          title_1: 'Τεχνικη εργασια',
          title_2: 'Γενική επισκευή κλαρινέτου - κλαρινέτου',
          image: 'assets/img/technical_work/clarinetrepair.jpg',
          description: 'Η γενική επισκευή του κλαρίνου, κλαρινέτου περιλαμβάνει αρχικά την αποσυναρμολόγηση του οργάνου, εσωτερικό και εξωτερικό καθαρισμό του ξύλου με ειδικό υγρό, καθαρισμό και γυάλισμα των κλειδιών, καθαρισμό των ελατηρίων και αλλαγή όσων χρειάζονται, αφαίρεση των ταπών και αντικατάσταση με δερμάτινες ή από μεμβράνη, αλλαγή των φελών των κλειδιών και των συνδέσμων, λίπανση του ξύλου με ειδικό λιπαντικό, λίπανση των αξόνων και των βιδών, συναρμολόγηση, ρύθμιση των κλειδιών, των ελατηρίων και γενικό τεχνικό έλεγχο..',
        },
        {
          div_id: 'guidemodal2',
          title_1: 'Τεχνικη εργασια',
          title_2: 'Γενική επισκευή σαξοφώνου, άλτο, τενόρο, σοπράνο',
          image: 'assets/img/technical_work/saxsmall.png',
          description: 'Η γενική επισκευή σαξοφώνου άλτο, τενόρο, σοπράνο περιλαμβάνει αρχικά την αποσυναρμολόγηση του οργάνου, εσωτερικό και εξωτερικό καθαρισμό του οργάνου και του λαιμού, καθαρισμό και γυάλισμα του οργάνου και των κλειδιών, καθαρισμό των ελατηρίων και αλλαγή όσων χρειάζονται, αφαίρεση των ταπών και αντικατάσταση με δερμάτινες αδιάβροχες τάπες, αλλαγή των φελών των κλειδιών και του λαιμού, λίπανση των αξόνων και των βιδών, συναρμολόγηση, ρύθμιση των κλειδιών, των ελατηρίων και γενικό τεχνικό έλεγχο...'
        },
        {
          div_id: 'guidemodal3',
          title_1: 'Τεχνικη εργασια',
          title_2: 'Γενική επισκευή φλάουτου',
          image: 'assets/img/technical_work/flutev2.jpg',
          description: 'Η γενική επισκευή του φλάουτου περιλαμβάνει αρχικά την αποσυναρμολόγηση του οργάνου, τον εσωτερικό και εξωτερικό καθαρισμό και το γυάλισμα του λαιμού, του σώματος, του ποδιού και των κλειδιών, το καθάρισμα και την αλλαγή όσων ελατηρίων χρειάζονται, την αφαίρεση και την αντικατάσταση των ταπών, την αντικατάσταση των τσοχών και των φελλών, την ρύθμιση των ελατηρίων και των κλειδιών και γενικό τεχνικό έλεγχο..'
        },
        {
          div_id: 'guidemodal4',
          title_1: 'Τεχνικη εργασια',
          title_2: 'Γενική επισκευή τρομπέτας',
          image: 'assets/img/technical_work/trumpet.jpg',
          description: 'Η γενική επισκευή τρομπέτας περιλαμβάνει αρχικά αποσυναρμολόγηση του οργάνου, εσωτερικό και εξωτερικό καθαρισμό της καμπάνας,των βαλβίδων, των σωλήνων και των πομπών με ειδική βούρτσα και καθαριστικό υγρό, σιδέρωμα της καμπάνας, σφυριλάτηση των χτυπημάτων, αλλαγή ελατηρίων αν χρειάζεται, λίπανση των πομπών και των βαλβίδων και γενικό τεχνικό έλεγχο.'
        }
      ]
    }
  },
}
</script>

<style scoped>

</style>
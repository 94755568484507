<template>
  <section class="bg-light">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center">
          <h2 class="text-uppercase section-heading">Cookies<br></h2>
          <br>
          <h3 class="section-subheading"><em>Τα cookie είναι ένα μικρό τμήμα κειμένου που
            αποστέλεται στο πρόγραμμα περιήγησης από τον ιστότοπο που επισκέπτεστε. Διευκολύνει τον
            ιστότοπο να απομνημονεύσει πληροφορίες σχετικά με την επίσκεψή σας, όπως την
            προτιμώμενη γλώσσα και άλλες ρυθμίσεις. Κάτι τέτοιο μπορεί να διευκολύνει την επόμενη σας
            επίσκεψη και να κάνει την ιστοσελίδα χρησιμότερη για εσάς. Τα cookie παίζουν σημαντικό ρόλο.
            Χωρίς αυτά η χρήστη του ιστότοπου θα ήταν μία πιο περίπλοκη διαδικασία. Χρησιμοποιούμε
            cookies για να μπορέσουμε να βελτιώσουμε την εμπειρία σας στον ιστότοπο μας, αλλά και για
            την μέτρηση επισκεψημότητας μέσω της Google.</em><br></h3>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AppCookies"
}
</script>

<style scoped>
.bg-light {
  background-image: url('../../public/assets/img/cookies/cookies1.jpg');
}

.text-uppercase.section-heading {
  color: #fff;
}

.section-subheading em {
  color: #fff;
  font-weight: bold;
}

</style>